/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// core components/views for Admin layout
import User from "views/User/User";
import Organization from "views/Organization/Organization";
import Report from "views/Report/Report";
import System from "views/System/System";
import Login from "views/Login/Login";

const dashboardRoutes = [
  {
    path: "/user",
    name: "Users",
    icon: PersonIcon,
    component: User,
    layout: "" 
  },
  {
    path: "/organization",
    name: "Organization",
    icon: AccountTreeIcon,
    component: Organization,
    layout: "" 
  },
  {
    path: "/report",
    name: "Report",
    icon: AssignmentIcon,
    component: Report,
    layout: ""
  },
  {
    path: "/system",
    name: "System Configuration",
    icon: SettingsIcon,
    component: System,
    layout: ""
  },
  // {
  //   path: "/login",
  //   name: "Logout",
  //   icon: ExitToAppIcon,
  //   component: Login,
  //   layout: ""
  // }
];

export default dashboardRoutes;
