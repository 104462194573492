import React, { useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
//core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { AITextField } from "components/AIComponents/AIComponents";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
//@material-ui/pickers
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//services
import * as REPORT from "../../services/report";
//assets
import { themeColor } from "assets/jss/material-dashboard-react";

const styles = {
  root: {
    width: 150,
  },
  cardFooter: {
    margin: "15px 20px",
    padding: "15px 15px",
    background: "#f7f7f7",
    borderRadius: "5px",
    justifyContent: "flex-start",
  },
};

const useStyles = makeStyles(styles);

export default function Report(props) {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orgCount, setOrgCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [loading, setLoading] = useState(false);
  let params = {};

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function fetchOrgTotal() {
    setLoading(true);
    REPORT.getOrgList(params)
      .then((result) => {
        setOrgCount(result.pagination.total);
        setLoading(false);
      })
      .catch((err) => {
        if (err === "unauthorized") {
          props.history.replace("/login");
        }
      });
  }

  function fetchUserTotal() {
    setLoading(true);
    REPORT.getUserList(params)
      .then((result) => {
        setUserCount(result.pagination.total);
        setLoading(false);
      })
      .catch((err) => {
        if (err === "unauthorized") {
          props.history.replace("/login");
        }
      });
  }

  function fetchProjectTotal() {
    setLoading(true);
    REPORT.getProjectList(params)
      .then((result) => {
        setProjectCount(result.pagination.total);
        setLoading(false);
      })
      .catch((err) => {
        if (err === "unauthorized") {
          props.history.replace("/login");
        }
      });
  }

  useEffect(() => {
    if (isValid(startDate)) {
      params.from = format(startDate, "yyyy-MM-dd");
    }
    if (isValid(endDate)) {
      params.to = format(endDate, "yyyy-MM-dd");
    }
    fetchOrgTotal();
    fetchUserTotal();
    fetchProjectTotal();
  }, [startDate, endDate]);

  return (
    <Card>
      <CardBody>
        <GridContainer display="row" style={{ padding: "5px 10px" }}>
          <GridItem>
            <AITextField
              style={{ minWidth: 200 }}
              select
              fullWidth
              label="Select Report Type"
              className={classes.select}
              variant="outlined"
              id="type"
              name="type"
              margin="normal"
              value={selectedType}
              onChange={handleTypeChange}
              input={<Input id="type" />}
            >
              <MenuItem key={1} value="1">
                Organization Report
              </MenuItem>
              <MenuItem key={2} value="2">
                User Report
              </MenuItem>
              <MenuItem key={3} value="3">
                Project Report
              </MenuItem>
            </AITextField>
          </GridItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GridItem>
              <FormControl classes={{ root: classes.root }}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date"
                  label="From"
                  value={startDate}
                  onChange={handleStartDateChange}
                  TextFieldComponent={AITextField}
                  KeyboardButtonProps={{
                    "aria-label": "change start date",
                  }}
                  disableFuture
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl classes={{ root: classes.root }}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date"
                  label="To"
                  value={endDate}
                  onChange={handleEndDateChange}
                  TextFieldComponent={AITextField}
                  KeyboardButtonProps={{
                    "aria-label": "change end date",
                  }}
                  minDate={startDate}
                  disableFuture
                />
              </FormControl>
            </GridItem>
          </MuiPickersUtilsProvider>
        </GridContainer>
      </CardBody>
      {/* <Divider variant="middle"/> */}
      <CardFooter className={classes.cardFooter}>
        <Typography style={{ fontWeight: 500 }}>
          Total{" "}
          {selectedType == 1
            ? "Organization"
            : selectedType == 2
            ? "Users"
            : "Project"}{" "}
          :
        </Typography>
        <Typography variant="h6" style={{ paddingLeft: 10, color: themeColor }}>
          {selectedType == 1
            ? orgCount
            : selectedType == 2
            ? userCount
            : projectCount}
        </Typography>
      </CardFooter>
    </Card>
  );
}
