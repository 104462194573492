import React, { useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import Swal from "sweetalert2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { AITextField } from "components/AIComponents/AIComponents";
//services
import * as USER from "../../services/user";
import { getOrgList } from "../../services/organization";
//assets and variables
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { countryCode } from "variables/countryCode";
import avatar from "assets/img/faces/marc.jpg";
import styles from "assets/jss/userStyle";

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function UserDetails(props) {
  const classes = useStyles();
  const [userStatusList, setUserStatusList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  // const [mobileNumPrefixError, setMobileNumPrefixError] = useState(false);
  // const [mobileNumError, setMobileNumError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const onOrg =
    props.location.state && props.location.state.org
      ? props.location.state.org
      : null;
  const onRole =
    props.location.state && props.location.state.role
      ? props.location.state.role
      : null;
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;
  const onStartDate =
    props.location.state && props.location.state.from
      ? props.location.state.from
      : null;
  const onEndDate =
    props.location.state && props.location.state.to
      ? props.location.state.to
      : null;
  const fromPath =
    props.location.state && props.location.state.fromPath
      ? props.location.state.fromPath
      : null;

  const [formData, setFormData] = useState({
    id: props.match.params.id,
    name: "",
    email: "",
    password: "",
    hasChangePass: false,
    mobile_number_select: "60",
    mobile_prefix: "60",
    mobile_number: "",
    organization: "",
    role: "",
    status: "",
  });

  const {
    name,
    email,
    password,
    mobile_number_select,
    mobile_prefix,
    mobile_number,
    organization,
    role,
    status,
  } = formData;

  function fetchUserDetails() {
    setLoading(true);
    USER.getUser(props.match.params.id)
      .then((result) => {
        if (result.status == "success") {
          setUser(result.data);
          setFormData({
            ...formData,
            ["name"]: result.data.name,
            ["email"]: result.data.email,
            ["mobile_prefix"]: result.data.mobile_prefix
              ? result.data.mobile_prefix
              : "60",
            ["mobile_number"]: result.data.mobile_number,
            ["mobile_number_select"]: result.data.mobile_number_prefix
              ? countryCode.find(
                  (cc) => cc.value == result.data.mobile_number_prefix
                ) !== undefined
                ? result.data.mobile_number_prefix
                : "Others"
              : "60",
            ["organization"]: result.data.organization
              ? result.data.organization.id
              : "Select Organization",
            ["role"]: result.data.role,
            ["status"]: result.data.status_name,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err == "unauthorized") {
          props.history.replace("/login");
        } else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found",
            }).then(() => {
              props.history.replace("/user");
            });
          }
        }
      });
  }

  useEffect(() => {
    getOrgList()
      .then((res) => {
        setOrgList(res.data);
      })
      .then((res) => {
        USER.getRoleList()
          .then((res) => {
            setRoleList(res.data);
          })
          .then((res) => {
            USER.getUserStatusList()
              .then((res) => {
                setUserStatusList(res.data);
              })
              .then((res) => {
                fetchUserDetails();
              });
          });
      });
  }, []);

  const updateFormData = (event) => {
    if (event.target.name === "mobile_number_select") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        ["mobile_prefix"]: event.target.value,
      });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    // setMobileNumError(false);
    // setEmailError(false);
    let valid = true;
    // if (!re.test(mobile_number)) {
    //   setMobileNumError(true);
    //   setLoading(false);
    //   valid = false;
    // }
    // if (isNaN(event.target.mobile_number_prefix.value)) {
    //   setMobileNumPrefixError(true);
    //   setLoading(false);
    //   valid = false;
    // }

    if (valid) {
      let updateData = formData;
      let org = null;
      if (organization !== "Select Organization") {
        org = organization;
      }
      updateData = {
        ...updateData,
        organization: org,
        status: Object.entries(userStatusList).find(
          (status) => status[1] == formData.status
        )[0],
        role: Object.entries(formData.role).map(
          (roleData) =>
            Object.entries(roleList).find((role) => role[1] == roleData[1])[0]
        ),
      };
      // setUpdateLoad(true);
      setLoading(true);
      USER.updateUser(updateData)
        .then((res) => {
          USER.updateUserRole(updateData).then((res) => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "User updated successfully",
            });
            setLoading(false);
          });
        })
        .catch((err) => {
          if (err.errors) {
            setLoading(false);
            // if (err.errors.email) {
            //   setEmailError(true);
            //   setEmailErrorMsg(err.errors.email[0]);
            // }
          }
          setLoading(false);
        });
    }
  }

  return loading ? (
    <GridContainer justify="center" alignItems="center">
      <div className={classes.load}>
        <CircularProgress style={{ fontSize: 30, color: themeColor }} />
      </div>
    </GridContainer>
  ) : (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={() =>
          props.history.replace({
            pathname: fromPath ? fromPath : "/user",
            state: {
              currentPage: page,
              search: search,
              org: onOrg,
              role: onRole,
              status: onStatus,
              from: isValid(onStartDate) ? format(onStartDate, "yyyy-MM-dd") : onStartDate,
              to: isValid(onEndDate) ? format(onEndDate, "yyyy-MM-dd") : onEndDate,
            },
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Edit User Profile</h4>
                <p className={classes.cardCategoryWhite}>
                  {"Joined Since " +
                    user.joined_since.substring(0, 10) +
                    " " +
                    user.joined_since.substring(11, 19)}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      required
                      fullWidth={true}
                      style={{ marginLeft: 0, marginTop: 0 }}
                      error={emailError}
                      className={classes.formControl}
                    >
                      <AITextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email"
                        name="email"
                        onChange={(e) => updateFormData(e)}
                        value={email}
                      />
                      {emailError && (
                        <FormHelperText>{emailErrorMsg}</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      select
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      id="organization"
                      label="Organization"
                      name="organization"
                      onChange={(e) => updateFormData(e)}
                      value={organization}
                    >
                      <MenuItem key={"none"} value={"Select Organization"}>
                        -- Select Organization --
                      </MenuItem>
                      {orgList.map((org) => (
                        <MenuItem key={org.id} value={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ maxWidth: "fit-content" }}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.inputLabel}>
                        Role
                      </InputLabel>
                      <Select
                        multiple
                        required
                        fullWidth
                        variant="standard"
                        name="role"
                        value={role}
                        onChange={(e) => updateFormData(e)}
                        input={<Input />}
                        className={classes.select}
                        classes={{
                          select: classes.selectInput,
                        }}
                      >
                        {Object.entries(roleList).map((role) => (
                          <MenuItem key={role[0]} value={role[1]}>
                            {role[1]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    style={{ maxWidth: "fit-content" }}
                  >
                    <AITextField
                      select
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      name="status"
                      label="Status"
                      value={status}
                      onChange={(e) => updateFormData(e)}
                      InputProps={{
                        classes: {
                          input:
                            status == "Active"
                              ? classes.activeColor
                              : status == "Inactive"
                              ? classes.inactiveColor
                              : classes.bannedColor,
                        },
                      }}
                    >
                      {Object.entries(userStatusList).map((status) => (
                        <MenuItem
                          key={status[0]}
                          value={status[1]}
                          style={{
                            color:
                              status[0] == 1
                                ? "#4caf50"
                                : status[0] == 2
                                ? "#f44336"
                                : "black",
                          }}
                        >
                          {status[1]}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Update User
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
