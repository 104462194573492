import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
//@material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
//core components
import { AITextField } from "components/AIComponents/AIComponents";
import Button from "components/CustomButtons/Button";
//services
import * as AUTH from "../../services/auth";
//assets
import { themeColor } from "assets/jss/material-dashboard-react";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//         Your Website
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    // backgroundColor: themeColor,
    // color: "#fff"
  },
}));

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function Login(props) {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);

  function redirectToAdmin() {
    props.history.replace("/user");
  }

  function handleLogin(event) {
    event.preventDefault();
    setLoading(true);
    let username = event.target.username.value;
    let pass = event.target.password.value;
    let loginData = {
      username: username,
      password: pass,
    };
    AUTH.login(loginData)
      .then((result) => {
        Toast.fire({
          icon: "success",
          title: "Logged in successfully",
        });
        redirectToAdmin();
      })
      .catch((e) => {
        setLoading(false);
        if (e.status === "unauthorized") {
          setErrorMsg(e.message);
        } 
      });
  }


  useEffect(() => {
    let todayDate = new Date();
    if (localStorage.getItem("tokenData") !== null) {
      if (new Date(localStorage.getItem("expireAt")) > todayDate) {
        // setLoggedIn(true);
        redirectToAdmin();
      }
      else {
        props.history.replace("/login");
      }
    }
  }, []);

 

  return (
    <div>
      {loading ? <LinearProgress /> : null}
      <Container component="main" maxWidth="xs" style={{ marginTop: 80 }}>
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{flexDirection:'row', color: themeColor, marginBottom: 10}}>
          <Typography component="h1" align="center" variant="h3" style={{lineHeight: 1.5}}>
            ANT INTERNET ZOHO ADMIN
          </Typography>
        </div>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <div>
          <Typography component="h1" variant="h5">
           Login
          </Typography>
          </div> */}
          <form
            className={classes.form}
            onSubmit={(event) => handleLogin(event)}
          >
          <div style={{ color: "red" }}>{errorMsg}</div>
            <AITextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <AITextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          </form>
        </div>
        {/* <Box mt={8}>
        <Copyright />
      </Box> */}
      </Container>
    </div>
  );
}
