import React, { useState, useEffect, useRef } from "react";
import { format, isValid } from "date-fns";
import Swal from "sweetalert2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { AITextField } from "components/AIComponents/AIComponents";
import Danger from "components/Typography/Danger";
//services
import * as ORG from "../../services/organization";
//assets and variables
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { countryCode } from "variables/countryCode";
import styles from "assets/jss/organizationStyle.js";

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function AddOrganization(props) {
  const classes = useStyles();
  const [orgSecret, setOrgSecret] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [keyErrorMsg, setKeyErrorMsg] = useState("");
  const [generateLoad, setGenerateLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileNumError, setMobileNumError] = useState(false);
  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;
  const onStartDate =
    props.location.state && props.location.state.from
      ? props.location.state.from
      : null;
  const onEndDate =
    props.location.state && props.location.state.to
      ? props.location.state.to
      : null;

  const [formData, setFormData] = useState({
    name: "",
    integration_key: "",
    integration_secret: "",
    person_in_charge: "",
    mobile_prefix: "60",
    mobile_number: "",
    // status: "",
  });

  const {
    name,
    integration_key,
    integration_secret,
    person_in_charge,
    mobile_prefix,
    mobile_number,
    // status,
  } = formData;

  function generateOrgSecret() {
    ORG.generateOrgSecret().then((res) => {
      if (res.status == "success") {
        setOrgSecret(res.data);
        setFormData({
          ...formData,
          ["integration_secret"]: orgSecret,
        });
        setGenerateLoad(false);
      }
    });
  }
  useEffect(() => {
    generateOrgSecret();
  }, []);

  const updateOrgSecret = () => {
    setGenerateLoad(true);
    generateOrgSecret();
  };

  const updateFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setError(false);
    setKeyError(false);
    setMobileNumError(false);
    let valid = true;
    if (!re.test(event.target.mobile_number.value)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }

    if (valid) {
      let createData = formData;
      createData = {
        ...createData,
        // status: Object.entries(orgStatusList).find(
        //   (status) => status[1] == formData.status
        // )[0],
      };
      setLoading(true);
      ORG.addOrg(createData)
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Organization created successfully",
          });
          props.history.replace("/organization");
        })
        .catch((err) => {
          setLoading(false);
          if (err.errors) {
            if (err.errors.key) {
              setKeyError(true);
              setKeyErrorMsg(err.errors.key);
            }
          } else if (err.errors === undefined) {
            setError(true);
            setErrorMsg(
              "Something went wrong. Please make sure your input is valid and try again."
            );
          }
        });
    }
  }

  return loading ? (
    <GridContainer justify="center" alignItems="center">
      <div className={classes.load}>
        <CircularProgress style={{ fontSize: 30, color: themeColor }} />
      </div>
    </GridContainer>
  ) : (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={() =>
          props.history.replace({
            pathname: "/organization",
            state: {
              currentPage: page,
              search: search,
              status: onStatus,
              from: isValid(onStartDate)
                ? format(onStartDate, "yyyy-MM-dd")
                : onStartDate,
              to: isValid(onEndDate)
                ? format(onEndDate, "yyyy-MM-dd")
                : onEndDate,
            },
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Add Organization</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Organization Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl required fullWidth error={keyError}>
                      <AITextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="integration_key"
                        label="Integration Key"
                        name="integration_key"
                        onChange={(e) => updateFormData(e)}
                        value={integration_key}
                      />
                      {keyError && (
                        <FormHelperText>{keyErrorMsg}</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    classes={{
                      item: classes.item,
                    }}
                  >
                    <AITextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="integration_secret"
                      label="Integration Secret"
                      name="integration_secret"
                      onChange={(e) => updateFormData(e)}
                      value={integration_secret}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <div className={classes.generateBtnRoot}>
                      <div className={classes.generateBtnWrapper}>
                        <Button
                          color="success"
                          style={{ width: 90 }}
                          disabled={generateLoad}
                          onClick={() => updateOrgSecret()}
                        >
                          Generate
                        </Button>
                        {generateLoad && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      id="person_in_charge"
                      label="Person-In-Charge"
                      name="person_in_charge"
                      onChange={(e) => updateFormData(e)}
                      value={person_in_charge}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <GridItem item xs={4} sm={3} md={4}>
                    <AITextField
                      required
                      fullWidth
                      margin="normal"
                      name="mobile_prefix"
                      select
                      label="Country"
                      value={mobile_prefix}
                      onChange={(e) => updateFormData(e)}
                    >
                      {countryCode.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                  <GridItem xs={8} sm={6} md={6}>
                    <FormControl required fullWidth error={mobileNumError}>
                      <AITextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        name="mobile_number"
                        value={mobile_number}
                        inputProps={{ maxLength: 20, minLength: 6 }}
                        onChange={(e) => updateFormData(e)}
                      />
                      {mobileNumError && (
                        <FormHelperText>Invalid mobile number.</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <AITextField
                      select
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      name="status"
                      label="Status"
                      value={status}
                      onChange={(e) => updateFormData(e)}
                      InputProps={{
                        classes: {
                          input:
                            status == "Active"
                              ? classes.activeColor
                              : status == "Inactive"
                              ? classes.inactiveColor
                              : classes.bannedColor,
                        },
                      }}
                    >
                      {Object.entries(orgStatusList).map((status) => (
                        <MenuItem
                          key={status[0]}
                          value={status[1]}
                          style={{
                            color:
                              status[0] == 1
                                ? "#4caf50"
                                : status[0] == 2
                                ? "#f44336"
                                : "black",
                          }}
                        >
                          {status[1]}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                </GridContainer> */}
                <Danger>{error ? errorMsg : null}</Danger>
              </CardBody>

              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
