import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { AITextField } from "components/AIComponents/AIComponents";
//services
import * as SYSTEM from "../../services/system";
//assets
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import styles from "assets/jss/systemStyle.js";

const useStyles = makeStyles(styles);
const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export default function UserDetails(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [addressInfo, setAddressInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [socialInfo, setSocialInfo] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [mobileNumError, setMobileNumError] = useState(false);

  const [addressData, setAddressData] = useState({
    street: "",
    city: "",
    state: "Kuala Lumpur",
    postcode: "",
    country: "Malaysia",
  });

  const [contactData, setContactData] = useState({
    contact_number: "",
    customer_service: "",
  });

  const [socialData, setSocialData] = useState({
    facebook: "",
    instagram: "",
    youtube: "",
  });

  const { street, city, state, postcode, country } = addressData;
  const { contact_number, customer_service } = contactData;
  const { facebook, instagram, youtube } = socialData;

  function fetchSiteInfo() {
    setLoading(true);
    SYSTEM.getSiteInfo()
      .then((result) => {
        if (result.status == "success") {
          setAddressInfo(result.data.address);
          setContactInfo(result.data.contact);
          setSocialInfo(result.data.profile);
          const findAddressData = (data) => {
            return result.data.address.find((address) => address.key == data);
          };
          const findContactData = (data) => {
            return result.data.contact.find((contact) => contact.key == data);
          };
          const findSocialData = (data) => {
            return result.data.profile.find((social) => social.key == data);
          };
          if (result.data.address) {
            setAddressData({
              ...addressData,
              ["street"]: findAddressData("street")
                ? findAddressData("street").value
                : "",
              ["city"]: findAddressData("city")
                ? findAddressData("city").value
                : "",
              ["state"]: findAddressData("state")
                ? findAddressData("state").value
                : "Kuala Lumpur",
              ["postcode"]: findAddressData("postcode")
                ? findAddressData("postcode").value
                : "",
              ["country"]: findAddressData("country")
                ? findAddressData("country").value
                : "Malaysia",
            });
          }

          if (result.data.contact) {
            setContactData({
              ["contact_number"]: findContactData("contact_number")
                ? findContactData("contact_number").value
                : "",
              ["customer_service"]: findContactData("customer_service")
                ? findContactData("customer_service").value
                : "",
            });
          }

          if (result.data.profile) {
            setSocialData({
              ["facebook"]: findSocialData("facebook")
                ? findSocialData("facebook").value
                : "",
              ["instagram"]: findSocialData("instagram")
                ? findSocialData("instagram").value
                : "",
              ["youtube"]: findSocialData("youtube")
                ? findSocialData("youtube").value
                : "",
            });
          }
        }
        // setLoading(false);
      })
      .catch((err) => {
        if (err == "unauthorized") {
          props.history.replace("/login");
        }
      });
  }

  let stateID = 1;
  let countryName = "MY";

  function fetchCountryList() {
    SYSTEM.getCountryList().then((result) => {
      setCountryList(result.data.list);
      if (result.data.list) {
        countryName = Object.entries(result.data.list).find(
          (countryData) => countryData[1].name == country
        )
          ? Object.entries(result.data.list).find(
              (countryData) => countryData[1].name == country
            )[0]
          : "";
      }
      fetchStateList(countryName);
    });
  }

  function fetchStateList(countryName) {
    SYSTEM.getStateList(countryName).then((result) => {
      setStateList(result.data.list);
      if (result.data.list) {
        stateID = result.data.list.find((stateData) => stateData.name == state)
          ? result.data.list.find((stateData) => stateData.name == state).id
          : "";
      }
      fetchCityList(stateID);
    });
  }

  function fetchCityList(stateID) {
    SYSTEM.getCityList(stateID).then((result) => {
      setCityList(result.data.list);
      if (result.status == "success") {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchCountryList();
  }, [state, country]);

  useEffect(() => {
    fetchSiteInfo();
  }, []);

  const updateAddressData = (event) => {
    setAddressData({ ...addressData, [event.target.name]: event.target.value });
  };

  const updateContactData = (event) => {
    setContactData({ ...contactData, [event.target.name]: event.target.value });
  };

  const updateSocialData = (event) => {
    setContactData({ ...socialData, [event.target.name]: event.target.value });
  };

  function handleAddressSubmit(event) {
    event.preventDefault();
    let addressDataArray = {
      id: addressInfo.map((address) => address.id),
      name: addressInfo.map((address) => address.name),
      value: Object.entries(addressData).map((address) => address[1]),
    };

    let updateAddressData = {
      name: Object.assign(
        ...addressDataArray.id.map((id, index) => ({
          [id]: addressDataArray.name[index],
        }))
      ),
      value: Object.assign(
        ...addressDataArray.id.map((id, index) => ({
          [id]: addressDataArray.value[index],
        }))
      ),
    };

    updateAddressData = { ...updateAddressData };
    setLoading(true);
    SYSTEM.updateSiteInfo(updateAddressData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Address Info updated successfully",
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.errors) {
          setLoading(false);
        }
        setLoading(false);
      });
  }

  function handleContactSubmit(event) {
    event.preventDefault();
    setMobileNumError(false);
    let valid = true;
    if (!re.test(contact_number) || !re.test(customer_service)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }
    if (valid) {
      let contactDataArray = {
        id: contactInfo.map((contact) => contact.id),
        name: contactInfo.map((contact) => contact.name),
        value: Object.entries(contactData).map((contact) => contact[1]),
      };

      let updateContactData = {
        name: Object.assign(
          ...contactDataArray.id.map((id, index) => ({
            [id]: contactDataArray.name[index],
          }))
        ),
        value: Object.assign(
          ...contactDataArray.id.map((id, index) => ({
            [id]: contactDataArray.value[index],
          }))
        ),
      };

      updateContactData = { ...updateContactData };
      setLoading(true);

      SYSTEM.updateSiteInfo(updateContactData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Contact Info updated successfully",
          });
          setLoading(false);
        })
        .catch((err) => {
          if (err.errors) {
            setLoading(false);
          }
          setLoading(false);
        });
    }
  }

  function handleSocialSubmit(event) {
    event.preventDefault();
    let socialDataArray = {
      id: socialInfo.map((social) => social.id),
      name: socialInfo.map((social) => social.name),
      value: Object.entries(socialData).map((social) => social[1]),
    };

    let updateSocialData = {
      name: Object.assign(
        ...socialDataArray.id.map((id, index) => ({
          [id]: socialDataArray.name[index],
        }))
      ),
      value: Object.assign(
        ...socialDataArray.id.map((id, index) => ({
          [id]: socialDataArray.value[index],
        }))
      ),
    };

    updateSocialData = { ...updateSocialData };
    setLoading(true);

    SYSTEM.updateSiteInfo(updateSocialData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Social Profile updated successfully",
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.errors) {
          setLoading(false);
        }
        setLoading(false);
      });
  }

  return loading ? (
    <GridContainer justify="center" alignItems="center">
      <div className={classes.load}>
        <CircularProgress style={{ fontSize: 30, color: themeColor }} />
      </div>
    </GridContainer>
  ) : (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleAddressSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Address Info</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      //required
                      fullWidth
                      label="Street Address"
                      id="street"
                      name="street"
                      value={street}
                      onChange={(e) => updateAddressData(e)}
                      inputProps={{
                        maxLength: 255,
                        minLength: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      id="city"
                      name="city"
                      value={city}
                      onChange={(e, newCity) =>
                        setAddressData({ ...addressData, ["city"]: newCity })
                      }
                      renderInput={(params) => (
                        <AITextField
                          {...params}
                          //required
                          fullWidth
                          label="City"
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      options={cityList.map((city) => city.name)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      id="state"
                      name="state"
                      value={state}
                      onChange={(e, newState) =>
                        setAddressData({
                          ...addressData,
                          ["state"]: newState,
                          ["city"]: "",
                        })
                      }
                      renderInput={(params) => (
                        <AITextField
                          {...params}
                          fullWidth
                          label="State"
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      options={stateList.map((state) => state.name)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      fullWidth
                      id="postcode"
                      label="Postcode"
                      name="postcode"
                      value={postcode}
                      onChange={(e) => updateAddressData(e)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      name="country"
                      value={country}
                      onChange={(e, newCountry) =>
                        setAddressData({
                          ...addressData,
                          ["country"]: newCountry,
                          ["state"]: "",
                          ["city"]: ""
                        })
                      }
                      renderInput={(params) => (
                        <AITextField
                          {...params}
                          fullWidth
                          label="Country"
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      options={Object.entries(countryList).map(
                        (country) => country[1].name
                      )}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Update Address
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleContactSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Contact Info</h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth error={mobileNumError}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {mobileNumError && (
                        <FormHelperText style={{ marginTop: 0 }}>
                          Invalid contact info.
                        </FormHelperText>
                      )}
                      <AITextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label="Contact Number"
                        id="contact_number"
                        name="contact_number"
                        value={contact_number}
                        type="tel"
                        onChange={(e) => updateContactData(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <AITextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="customer_service"
                        label="Customer Service"
                        name="customer_service"
                        value={customer_service}
                        type="tel"
                        onChange={(e) => updateContactData(e)}
                      />
                    </GridItem>
                  </GridContainer>
                </FormControl>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Update Contact
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleSocialSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Social Profile</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      // //required
                      fullWidth
                      label="Facebook"
                      id="facebook"
                      name="facebook"
                      value={facebook}
                      onChange={(e) => updateSocialData(e)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      // //required
                      fullWidth
                      id="instagram"
                      label="Instagram"
                      name="instagram"
                      value={instagram}
                      onChange={(e) => updateSocialData(e)}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      // //required
                      fullWidth
                      id="youtube"
                      label="Youtube"
                      name="youtube"
                      value={youtube}
                      onChange={(e) => updateSocialData(e)}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Update Profile
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
