import * as AUTH from "./auth";
const axios = require("axios");

export async function getSiteInfo() {
  let accessToken = await AUTH.getAccessToken();
  let url =
    process.env.REACT_APP_API_URL +
    "/" +
    process.env.REACT_APP_API_PREFIX +
    "/site-info";
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
      .finally();
  });
}

export async function updateSiteInfo(updateData) {
  let accessToken = await AUTH.getAccessToken();
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/" +
          process.env.REACT_APP_API_PREFIX +
          "/update-site-info",
        {
          name: updateData.name,
          value: updateData.value,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (!accessToken) {
          reject("token");
        } else {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        }
      })
      .finally();
  });
}

export async function getCityList(stateID) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/city-list/" +
      stateID;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }
  
  export async function getStateList(countryCode) {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/state-list/" +
      countryCode;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }
  
  export async function getCountryList() {
    let accessToken = await AUTH.getAccessToken();
    let url =
      process.env.REACT_APP_API_URL +
      "/" +
      process.env.REACT_APP_API_PREFIX +
      "/country-list";
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
        .finally();
    });
  }
  