import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { format, isValid } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { InputLabel, FormControl, Input } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { AITextField } from "components/AIComponents/AIComponents";
//@material-ui/pickers
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//services
import * as USER from "../../services/user";
import { getOrgList } from "../../services/organization";
//assets
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import styles from "assets/jss/userStyle";

const useStyles = makeStyles(styles);

export default function User(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userList, setUserList] = useState([]);
  const [userStatusList, setUserStatusList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("All");
  const [selectedRole, setSelectedRole] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [onFilter, setOnFilter] = useState("");
  const [route, setRoute] = useState({});
  const [currentPage, setCurrentPage] = useState(
    props.location.state && props.location.state.currentPage
      ? props.location.state.currentPage
      : 1
  );
  const [pagination, setPagination] = useState([]);
  const [pageArr, setPageArr] = useState([]);
  let params = {};

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }, 500);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  const goToPage = (e) => {
    setCurrentPage(e.target.value);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }, 500);
  };

  function fetchUserList() {
    USER.getUserList(currentPage, params)
      .then((result) => {
        let page = [];
        for (let i = 0; i < result.pagination.last_page; i++) {
          page.push(i + 1);
        }
        setPageArr(page);
        setPagination(result.pagination);
        setUserList(result.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err === "unauthorized") {
          props.history.replace("/login");
        }
      });
  }

  useEffect(() => {
    if (onFilter) {
      if (selectedOrg !== "All") {
        params.org = selectedOrg;
      }
      if (selectedStatus !== "All") {
        params.status = selectedStatus;
      }
      if (selectedRole !== "All") {
        params.role = selectedRole;
      }
      if (searchInput.length > 0) {
        params.search = searchInput;
      }
      if (isValid(startDate)) {
        params.from = format(startDate, "yyyy-MM-dd");
      }
      if (isValid(endDate)) {
        params.to = format(endDate, "yyyy-MM-dd");
      }
    }
    getOrgList().then((res) => {
      setOrgList(res.data);
    });
    USER.getRoleList().then((res) => {
      setRoleList(res.data);
    });
    USER.getUserStatusList()
      .then((res) => {
        setUserStatusList(res.data);
      })
      .then(() => {
        if (props.location.state) {
          if (props.location.state.org) {
            params.org = props.location.state.org;
            setSelectedOrg(props.location.state.org);
            props.location.state.org = null;
          }
          if (props.location.state.search) {
            params.search = props.location.state.search;
            setSearchInput(params.search);
            props.location.state.search = null;
          }
          if (props.location.state.role) {
            params.role = props.location.state.role;
            setSelectedRole(props.location.state.role);
            props.location.state.role = null;
          }
          if (props.location.state.status) {
            params.status = props.location.state.status;
            setSelectedStatus(props.location.state.status);
            props.location.state.status = null;
          }
          if (props.location.state.from) {
            params.from = props.location.state.from;
            setStartDate(props.location.state.from);
            props.location.state.from = null;
          }
          if (props.location.state.to) {
            params.to = props.location.state.to;
            setEndDate(props.location.state.to);
            props.location.state.to = null;
          }
        }
        props.history.replace({
          pathname: "/user",
          state: {},
        });
        fetchUserList();
      })
      .catch((err) => {
        if (err === "unauthorized") {
          props.history.replace("/login");
        }
      });
  }, [onFilter, currentPage]);

  const handleOrgChange = (event) => {
    const options = event.target.value;
    setSelectedOrg(options);
  };

  const handleRoleChange = (event) => {
    const options = event.target.value;
    setSelectedRole(options);
  };

  const handleStatusChange = (event) => {
    const options = event.target.value;
    setSelectedStatus(options);
  };

  const handleSearchInput = (event) => {
    const options = event.target.value;
    setSearchInput(options);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      filterThis();
    }
  };

  const filterThis = () => {
    setLoading(true);
    setCurrentPage(1);
    setOnFilter(
      searchInput +
        selectedOrg +
        selectedStatus +
        selectedRole +
        startDate +
        endDate +
        new Date()
    );
  };

  const clearFilter = () => {
    setLoading(true);
    setStartDate(null);
    setEndDate(null);
    setSelectedStatus("All");
    setSelectedRole("All");
    setSelectedOrg("All");
    setSearchInput("");
    setCurrentPage(1);
    setOnFilter(new Date());
  };

  const paramPass = {
    page: currentPage,
    search: searchInput,
    from: startDate,
    to: endDate,
    org: selectedOrg !== "All" ? selectedOrg : null,
    role: selectedRole !== "All" ? selectedRole : null,
    status: selectedStatus !== "All" ? selectedStatus : null,
  };
  // if (route.action == "add") {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/user/add",
  //         state: paramPass,
  //       }}
  //     />
  //   );
  // } else
  if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/user/" + route.id,
          state: paramPass,
        }}
      />
    );
  }

  return loading ? (
    <GridContainer justify="center" alignItems="center">
      <div className={classes.load}>
        <CircularProgress style={{ fontSize: 30, color: themeColor }} />
      </div>
    </GridContainer>
  ) : (
    <GridContainer id="table">
      <GridItem xs={12} sm={12} md={12}>
        {/* <Button
          variant="contained"
          style={{ backgroundColor: themeColor, color: "white" }}
          className={classes.submit}
          onClick={() => routeTo("add")}
        >
          <Icon style={{ paddingRight: 2, fontSize: 19 }}>add</Icon>
          Add User
        </Button> */}
        <Card>
          <CardBody>
            <GridContainer style={{ paddingBottom: 10 }}>
              <div style={{ flexGrow: 1 }}>
                <GridItem xs>
                  <AITextField
                    variant="filled"
                    margin="dense"
                    fullWidth
                    id="search"
                    label="Search"
                    name="search"
                    placeholder={"Search by email or name"}
                    onKeyDown={keyPress}
                    value={searchInput}
                    onChange={handleSearchInput}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.input,
                    }}
                    className={classes.searchInput}
                  />
                </GridItem>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GridItem>
                  <FormControl classes={{ root: classes.root }}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="dense"
                      id="start-date"
                      label="From"
                      value={startDate}
                      onChange={handleStartDateChange}
                      TextFieldComponent={AITextField}
                      KeyboardButtonProps={{
                        "aria-label": "change start date",
                      }}
                      disableFuture
                    />
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl classes={{ root: classes.root }}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="dense"
                      id="end-date"
                      label="To"
                      value={endDate}
                      onChange={handleEndDateChange}
                      TextFieldComponent={AITextField}
                      KeyboardButtonProps={{
                        "aria-label": "change end date",
                      }}
                      minDate={startDate}
                      disableFuture
                    />
                  </FormControl>
                </GridItem>
              </MuiPickersUtilsProvider>
            </GridContainer>
            <GridContainer style={{ paddingBottom: 30 }}>
              <GridItem
                xs={3}
                sm={3}
                md={2}
                style={{ maxWidth: 200 }}
                className={classes.filterGrid}
              >
                <AITextField
                  style={{ minWidth: 160 }}
                  select
                  fullWidth
                  label="Select Organization"
                  className={classes.select}
                  variant="outlined"
                  id="role"
                  name="role"
                  margin="dense"
                  value={selectedOrg}
                  onChange={handleOrgChange}
                  input={<Input id="org" />}
                >
                  <MenuItem key={"all"} value="All">
                    <ListItemText primary={"All"} />
                  </MenuItem>
                  {orgList.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      <ListItemText primary={org.name} />
                    </MenuItem>
                  ))}
                  />
                </AITextField>
              </GridItem>
              <GridItem
                xs={3}
                sm={2}
                md={2}
                style={{ minWidth: 150 }}
                className={classes.filterGrid}
              >
                <AITextField
                  // style={{ minWidth: 130 }}
                  select
                  fullWidth
                  className={classes.select}
                  id="role"
                  label="Select Role"
                  name="role"
                  variant="outlined"
                  margin="dense"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  input={<Input id="role" />}
                >
                  <MenuItem key={"all"} value={"All"}>
                    <ListItemText primary={"All"} />
                  </MenuItem>
                  {Object.entries(roleList).map((role) => (
                    <MenuItem key={role[0]} value={role[0]}>
                      <ListItemText primary={role[1]} />
                    </MenuItem>
                  ))}
                </AITextField>
              </GridItem>
              <div style={{ flexGrow: 1 }}>
                <GridItem
                  xs={3}
                  sm={2}
                  md={2}
                  style={{ minWidth: 150 }}
                  className={classes.filterGrid}
                >
                  <AITextField
                    select
                    fullWidth
                    className={classes.select}
                    label="Select Status"
                    id="status"
                    name="status"
                    variant="outlined"
                    margin="dense"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    input={<Input id="status" />}
                  >
                    <MenuItem key={"all"} value={"All"}>
                      <ListItemText primary={"All"} />
                    </MenuItem>
                    {Object.entries(userStatusList).map((status) => (
                      <MenuItem key={status[0]} value={status[0]}>
                        <ListItemText primary={status[1]} />
                      </MenuItem>
                    ))}
                  </AITextField>
                </GridItem>
              </div>
              {/* </GridContainer>

            <GridContainer
              direction="row"
              justify="flex-end"
              alignItems="center"
            > */}
              <GridItem style={{ display: "inline-flex", margin: "10px 0" }}>
                <Button
                  variant="contained"
                  role="submit"
                  color="primary"
                  className={classes.submit}
                  onClick={filterThis}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 12 }}
                  className={classes.submit}
                  onClick={clearFilter}
                >
                  Reset
                </Button>
              </GridItem>
            </GridContainer>
            <Divider light />
            {userList.length > 0 ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.th}>ID</TableCell>
                      <TableCell className={classes.th}>Name</TableCell>
                      <TableCell className={classes.th}>Email</TableCell>
                      <TableCell className={classes.th}>Organization</TableCell>
                      <TableCell className={classes.th}>Role</TableCell>
                      <TableCell className={classes.th}>Status</TableCell>
                      <TableCell className={classes.th}>Date Joined</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.map((user) => (
                      <TableRow
                        onClick={() => routeTo("view", user.id)}
                        className={classes.tr}
                        key={user.id}
                      >
                        <TableCell
                          className={classes.td}
                          component="th"
                          scope="row"
                        >
                          {user.id}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.name}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.email}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.organization ? user.organization.name : "-"}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.role + " "}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              user.status == 1
                                ? "#4caf50"
                                : user.status == 2
                                ? "#f44336"
                                : "black",
                            fontWeight: "400",
                          }}
                          className={classes.td}
                        >
                          {user.status_name}
                        </TableCell>
                        <TableCell className={classes.td}>
                          {user.created_at.substring(0, 10) +
                            " " +
                            user.created_at.substring(11, 19)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div style={{ paddingTop: 30, textAlign: "center" }}>
                <Typography style={{ fontWeight: "inherit" }}>
                  No user found
                </Typography>
              </div>
            )}
            {userList.length > 0 ? (
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ fontSize: 13 }}>
                  Page :
                  <Select
                    style={{
                      marginLeft: 10,
                      width: 60,
                      fontSize: 13,
                      textAlign: "center",
                      marginTop: 12,
                    }}
                    className={classes.select}
                    id="page"
                    name="page"
                    variant="outlined"
                    value={currentPage}
                    onChange={(e) => goToPage(e)}
                    input={<Input id="page" />}
                    renderValue={(selected) => selected}
                  >
                    {pageArr.map((page) => (
                      <MenuItem key={page} value={page}>
                        <ListItemText primary={page} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={pagination.total}
                  rowsPerPage={15}
                  page={currentPage - 1}
                  onChangePage={handleChangePage}
                />
              </div>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
