import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { format, isValid } from "date-fns";
import Swal from "sweetalert2";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import { AITextField } from "components/AIComponents/AIComponents";
//services
import * as ORG from "../../services/organization";
import { getUserList } from "../../services/user";
//assets and variables
import { themeColor } from "../../assets/jss/material-dashboard-react.js";
import { countryCode } from "variables/countryCode";
import styles from "assets/jss/organizationStyle.js";

const useStyles = makeStyles(styles);
const re = /^[0-9\b]+$/;

export default function OrganizationDetails(props) {
  const classes = useStyles();
  const [orgStatusList, setOrgStatusList] = useState([]);
  const [org, setOrg] = useState("");
  const [userList, setUserList] = useState([]);
  const [orgSecret, setOrgSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [generateLoad, setGenerateLoad] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [keyErrorMsg, setKeyErrorMsg] = useState("");
  const [mobileNumError, setMobileNumError] = useState(false);
  const [route, setRoute] = React.useState({});
  const [pagination, setPagination] = React.useState([]);
  const [pageArr, setPageArr] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    props.location.state && props.location.state.currentPage
      ? props.location.state.currentPage
      : 1
  );
  const page =
    props.location.state && props.location.state.page
      ? props.location.state.page
      : 1;
  const search =
    props.location.state && props.location.state.search
      ? props.location.state.search
      : "";
  const onStatus =
    props.location.state && props.location.state.status
      ? props.location.state.status
      : null;
  const onStartDate =
    props.location.state && props.location.state.from
      ? props.location.state.from
      : null;
  const onEndDate =
    props.location.state && props.location.state.to
      ? props.location.state.to
      : null;
  const [formData, setFormData] = useState({
    id: props.match.params.id,
    name: "",
    integration_key: "",
    integration_secret: "",
    person_in_charge: "",
    mobile_prefix: "60",
    mobile_number: "",
    status: "",
  });

  const {
    name,
    integration_key,
    integration_secret,
    person_in_charge,
    mobile_prefix,
    mobile_number,
    status,
  } = formData;

  const orgID = props.match.params.id;

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setTimeout(() => {
      document.getElementById("table").scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }, 500);
  };

  const routeTo = (action, id) => {
    setRoute({ action: action, id: id });
  };

  // const goToPage = (e) => {
  //   setCurrentPage(e.target.value);
  //   setTimeout(() => {
  //     document.getElementById("table").scrollIntoView({
  //       behavior: "auto",
  //       block: "start",
  //     });
  //   }, 500);
  // };

  function fetchOrgDetails() {
    setLoading(true);
    ORG.getOrg(orgID)
      .then((result) => {
        setOrg(result.data);
        if (result.status == "success") {
          setFormData({
            ...formData,
            ["name"]: result.data.name,
            ["integration_key"]: result.data.integration_key,
            ["integration_secret"]: result.data.integration_secret,
            ["person_in_charge"]: result.data.person_in_charge,
            ["mobile_prefix"]: result.data.mobile_prefix
              ? countryCode.find(
                  (cc) => cc.value == result.data.mobile_prefix.replace("+", "")
                ) !== undefined
                ? result.data.mobile_prefix.replace("+", "")
                : "Others"
              : "60",
            ["mobile_number"]: result.data.mobile_number
              ? result.data.mobile_number
              : "",
            ["status"]: result.data.status_name,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err == "unauthorized") {
          props.history.replace("/login");
        } else {
          if (err.status == "not found") {
            Swal.fire({
              icon: "error",
              title: "Opps",
              text: "Page Not Found",
            }).then(() => {
              props.history.replace("/organization");
            });
          }
        }
      });
  }

  useEffect(() => {
    let params = {};
    if (orgID) {
      params.org = orgID;
    }
    ORG.getOrgStatusList()
      .then((res) => {
        setOrgStatusList(res.data);
      })
      .then(() => {
        getUserList(currentPage, params)
          .then((result) => {
            let page = [];
            for (let i = 0; i < result.pagination.last_page; i++) {
              page.push(i + 1);
            }
            setPageArr(page);
            setPagination(result.pagination);
            setUserList(result.data);
            fetchOrgDetails();
          })
          .catch((err) => {
            if (err === "unauthorized") {
              props.history.replace("/login");
            }
          });
      })
      .catch((err) => {
        if (err == "unauthorized") {
          props.history.replace("/login");
        }
      });
    generateOrgSecret();
  }, [currentPage]);

  function generateOrgSecret() {
    ORG.generateOrgSecret().then((res) => {
      if (res.status == "success") {
        setOrgSecret(res.data);
        setFormData({
          ...formData,
          ["integration_secret"]: orgSecret,
        });
        setGenerateLoad(false);
      }
    });
  }

  const updateOrgSecret = () => {
    setGenerateLoad(true);
    generateOrgSecret();
  };

  const updateFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setError(false);
    setKeyError(false);
    setMobileNumError(false);
    let valid = true;
    if (!re.test(mobile_number)) {
      setMobileNumError(true);
      setLoading(false);
      valid = false;
    }

    if (valid) {
      let updateData = formData;
      updateData = {
        ...updateData,
        status: Object.entries(orgStatusList).find(
          (status) => status[1] == formData.status
        )[0],
      };
      setLoading(true);

      ORG.updateOrg(updateData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Organization updated successfully",
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.errors) {
            if (err.errors.key) {
              setKeyError(true);
              setKeyErrorMsg(err.errors.key);
            }
          } else if (err.errors === undefined) {
            setError(true);
            setErrorMsg(
              "Something went wrong. Please make sure your input is valid and try again."
            );
          }
        });
    }
  }

  if (route.action == "view") {
    return (
      <Redirect
        to={{
          pathname: "/user/" + route.id,
          state: { fromPath: props.location.pathname },
        }}
      />
    );
  }

  return loading ? (
    <GridContainer justify="center" alignItems="center">
      <div className={classes.load}>
        <CircularProgress style={{ fontSize: 30, color: themeColor }} />
      </div>
    </GridContainer>
  ) : (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={() =>
          props.history.replace({
            pathname: "/organization",
            state: {
              currentPage: page,
              search: search,
              status: onStatus,
              from: isValid(onStartDate)
                ? format(onStartDate, "yyyy-MM-dd")
                : onStartDate,
              to: isValid(onEndDate)
                ? format(onEndDate, "yyyy-MM-dd")
                : onEndDate,
            },
          })
        }
      >
        Back
      </Button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <form
              className={classes.form}
              onSubmit={(event) => handleSubmit(event)}
            >
              <CardHeader color="primary" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Edit Organization</h4>
                <p className={classes.cardCategoryWhite}>
                  {"Joined Since " +
                    org.created_at.substring(0, 10) +
                    " " +
                    org.created_at.substring(11, 19)}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      label="Organization Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => updateFormData(e)}
                      inputProps={{
                        maxLength: 100,
                        minLength: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl required fullWidth error={keyError}>
                      <AITextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="integration_key"
                        label="Integration Key"
                        name="integration_key"
                        onChange={(e) => updateFormData(e)}
                        value={integration_key}
                      />
                      {keyError && (
                        <FormHelperText>{keyErrorMsg}</FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    classes={{
                      item: classes.item,
                    }}
                  >
                    <AITextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="integration_secret"
                      label="Integration Secret"
                      name="integration_secret"
                      onChange={(e) => updateFormData(e)}
                      value={integration_secret}
                      disabled
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className={classes.generateBtnRoot}>
                      <div className={classes.generateBtnWrapper}>
                        <Button
                          color="success"
                          style={{ width: 90 }}
                          disabled={generateLoad}
                          onClick={() => updateOrgSecret()}
                        >
                          Generate
                        </Button>
                        {generateLoad && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <AITextField
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      id="person_in_charge"
                      label="Person-In-Charge"
                      name="person_in_charge"
                      onChange={(e) => updateFormData(e)}
                      value={person_in_charge}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <GridItem item xs={4} sm={3} md={4}>
                    <AITextField
                      required
                      fullWidth
                      margin="normal"
                      name="mobile_prefix"
                      select
                      label="Country"
                      value={mobile_prefix}
                      onChange={(e) => updateFormData(e)}
                    >
                      {countryCode.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                  <GridItem required xs={8} sm={6} md={6}>
                    <FormControl fullWidth error={mobileNumError}>
                      <AITextField
                        variant="standard"
                        type="tel"
                        required
                        fullWidth
                        margin="normal"
                        label="Mobile Number"
                        name="mobile_number"
                        value={mobile_number}
                        inputProps={{ maxLength: 20, minLength: 6 }}
                        onChange={(e) => updateFormData(e)}
                      />
                      {mobileNumError && (
                        <FormHelperText style={{ marginTop: 0 }}>
                          Invalid mobile number.
                        </FormHelperText>
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={3} md={4}>
                    <AITextField
                      select
                      required
                      fullWidth
                      variant="standard"
                      margin="normal"
                      name="status"
                      label="Status"
                      value={status}
                      onChange={(e) => updateFormData(e)}
                      InputProps={{
                        classes: {
                          input:
                            status == "Active"
                              ? classes.activeColor
                              : status == "Inactive"
                              ? classes.inactiveColor
                              : classes.bannedColor,
                        },
                      }}
                    >
                      {Object.entries(orgStatusList).map((status) => (
                        <MenuItem
                          key={status[0]}
                          value={status[1]}
                          style={{
                            color:
                              status[0] == 1
                                ? "#4caf50"
                                : status[0] == 2
                                ? "#f44336"
                                : "black",
                          }}
                        >
                          {status[1]}
                        </MenuItem>
                      ))}
                    </AITextField>
                  </GridItem>
                </GridContainer>
                <Danger>{error ? errorMsg : null}</Danger>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button type="submit" color="primary">
                  Update Organization
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card style={{ minWidth: 225 }}>
            <CardHeader className={classes.cardHeaderUser}>
              <h4 style={{ margin: "5px 0", fontWeight: 500 }}>
                Organization User List
              </h4>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              {userList.length > 0 ? (
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>User</TableCell>
                        <TableCell className={classes.th}>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList.map((user) => (
                        <TableRow
                          onClick={() => routeTo("view", user.id)}
                          className={classes.tr}
                          key={user.id}
                        >
                          <TableCell className={classes.td}>
                            {user.name}
                          </TableCell>
                          <TableCell className={classes.td}>
                            {user.role + " "}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div style={{ padding: "30px 0 15px", textAlign: "center" }}>
                  <Typography style={{ fontWeight: "inherit" }}>
                    No user found
                  </Typography>
                </div>
              )}

              {userList.length > 0 ? (
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <div style={{ fontSize: 13 }}>
                    Page :
                    <Select
                      style={{
                        marginLeft: 10,
                        width: 60,
                        fontSize: 13,
                        textAlign: "center",
                        marginTop: 12,
                      }}
                      className={classes.select}
                      id="page"
                      name="page"
                      variant="outlined"
                      value={currentPage}
                      onChange={(e) => goToPage(e)}
                      input={<Input id="page" />}
                      renderValue={(selected) => selected}
                    >
                      {pageArr.map((page) => (
                        <MenuItem key={page} value={page}>
                          <ListItemText primary={page} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div> */}
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={pagination.total}
                    rowsPerPage={15}
                    page={currentPage - 1}
                    onChangePage={handleChangePage}
                    classes={{
                      actions: classes.tablePaginationActions,
                    }}
                  />
                </div>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
