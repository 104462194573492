import { defaultFont } from "assets/jss/material-dashboard-react.js";
import { themeColor } from "./material-dashboard-react.js";

const userStyle = () => ({
  root: {
    width: 150,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  table: {
    width: "100%",
    overflowX: "auto",
  },
  th: {
    color: themeColor,
    ...defaultFont,
    padding: "10px 6px",
    textAlign: "left",
    fontSize: 15.5,
    fontWeight: "400",
  },
  td: {
    ...defaultFont,
    paddingTop: 8,
    paddingBottom: 8,
    padding: 6,
  },
  tr: {
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7",
    },
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer",
    },
  },
  load: {
    position: "fixed",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  cssLabel: {
    color: "gray",
    "&$cssFocused": {
      color: themeColor,
    },
  },
  cssFocused: {},
  inputLabel: {
    color: "gray",
    fontWeight: "400",
    "&.Mui-focused": {
      color: themeColor,
    },
  },
  inputFocused: {},
  select: {
    "&:after": {
      borderColor: themeColor,
    },
    "&:hover:not($disabled):before": {
      borderColor: themeColor,
    },
  },
  filterGrid: {
    padding: "0 5px !important",
  },
  searchInput: {
    backgroundColor: "#eeeeee",
    borderRadius: "4px !important",
  },
  input: {
    borderRadius: "4px !important",
  },

  //User Details
  cardHeader: {
    padding: "15px",
    marginTop: "-20px",
    borderRadius: "3px",
    margin: "0 15px",
    background: "linear-gradient(60deg, #2655da, #00acc1)",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4)",
  },
  activeColor: {
    color: "#4caf50",
    fontWeight: "400",
  },
  inactiveColor: {
    color: "#f44336",
    fontWeight: "400",
  },
  bannedColor: {
    color: "black",
    fontWeight: "400",
  },
  formControl: {
    minWidth: 40,
    marginTop: 16,
    marginBottom: 8,
  },
  selectInput: {
    minWidth: 40,
  },
});

export default userStyle;
