import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";

import { themeColor } from "assets/jss/material-dashboard-react";

export const AITextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: themeColor,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: themeColor,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: themeColor,
      },
    },
    "&.Mui-disabled:before": {
      backgroundColor: "transparent !important",
    },
  },
})(TextField);

export const AIButton = withStyles((theme) => ({
  root: {
    color: "#FFF",
    backgroundColor: themeColor,
    "&:hover": {
      backgroundColor: themeColor,
    },
  },
}))(Button);
